
import { ref } from "vue";

export default {
  name: "base-textarea",
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  setup() {
    const value = ref("");

    return { value };
  },
};
