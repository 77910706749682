import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "@/views/HomePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "Jacek Łudzik | Designer & Developer",
      description: "I'm developer and designer",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
