
import { defineComponent, reactive } from "vue";

interface IClient {
  logo: string;
  alt: string;
}

const CLIENTS: IClient[] = [
  { logo: "thecodest", alt: "The Codest Logo" },
  { logo: "cabanoss", alt: "Cabanoss logo" },
];

export default defineComponent({
  name: "clients-list",
  setup(): { clients: IClient[] } {
    const clients = reactive<IClient[]>(CLIENTS);

    return { clients };
  },
});
