
import { defineComponent } from "vue";
import AboutMePattern from "@/components/patterns/about-me-pattern.vue";
import BaseButton from "@/components/base/base-button.vue";
import { formatDistanceToNowStrict } from "date-fns";

export default defineComponent({
  name: "about",
  components: { BaseButton, AboutMePattern },
  setup() {
    const scrollToValues = () => {
      const values = document.querySelector("#values");

      if (!values) return;

      const { top } = values.getBoundingClientRect();

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    };

    const calculateAge = () => {
      const BIRTH_DATE = new Date(2000, 6, 5);

      return formatDistanceToNowStrict(BIRTH_DATE, { unit: "year" });
    };

    return { calculateAge, scrollToValues };
  },
});
