
import { defineComponent, PropType } from "vue";

type Variants = "primary" | "secondary";

export default defineComponent({
  name: "base-button",
  props: {
    href: {
      type: String,
      default: "",
    },
    variant: {
      type: String as PropType<Variants>,
      default: "primary",
    },
    type: {
      type: String as PropType<"button" | "submit">,
      default: "",
    },
    scrollTo: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    externalLink: {
      type: String,
      default: "",
    },
  },
});
