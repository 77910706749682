
import { defineComponent, PropType } from "vue";
import { IProject } from "@/types";
import BaseButton from "@/components/base/base-button.vue";

export default defineComponent({
  name: "project",
  components: { BaseButton },
  props: {
    project: Object as PropType<IProject>,
  },
});
