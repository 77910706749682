
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import Linkedin from "@/components/social-media/linkedin.vue";
import Dribbble from "@/components/social-media/dribbble.vue";

interface INavigation {
  name: string;
  link: string;
}

const NAVIGATION: INavigation[] = [
  { name: "Work", link: "#work" },
  { name: "About", link: "#about" },
  { name: "Contact", link: "#contact" },
];

export default defineComponent({
  name: "header",
  components: { Dribbble, Linkedin },
  setup() {
    const navigation = reactive<INavigation[]>(NAVIGATION);

    const isActive = ref(false);

    const activeLink = ref("");

    const isScrolled = () => {
      isActive.value = window.scrollY > 100;
    };

    /*
    [work, about].forEach((section) => {
      if (!section) return;

      observer.observe(section);
    });

     */

    onMounted(() => {
      window.addEventListener("scroll", isScrolled);

      const work = document.querySelector("#work");
      const about = document.querySelector("#about");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            activeLink.value = entry.target.id;
          } else {
            activeLink.value = "";
          }
        });
      });

      [work, about].forEach((section) => {
        if (!section) return;

        observer.observe(section);
      });
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", isScrolled);
    });

    return { navigation, isActive, activeLink };
  },
});
