
import { reactive } from "vue";
import BaseInput from "@/components/base/base-input.vue";
import BaseTextarea from "@/components/base/base-textarea.vue";
import BaseButton from "@/components/base/base-button.vue";
import { validateContactForm } from "@/utils/validate-contact-form";

export default {
  name: "contact-form",
  components: { BaseButton, BaseTextarea, BaseInput },
  setup() {
    const values = reactive({ name: "", email: "", message: "" });
    const formErrors = reactive({ name: "", email: "", message: "" });

    const updateName = ($value: string) => {
      values.name = $value;
    };

    const validateField = ($value: "name" | "email" | "message") => {
      const errors = validateContactForm(
        values.name,
        values.email,
        values.message
      );

      const fieldError = errors.find(({ field }) => field === $value);

      if (!fieldError) {
        formErrors[$value] = "";

        return;
      }

      const { field, message } = fieldError;

      formErrors[field] = message;
    };

    const updateEmail = ($value: string) => {
      values.email = $value;
    };

    const updateMessage = ($value: string) => {
      values.message = $value;
    };

    const resetForm = () => {
      values.name = "";
      values.email = "";
      values.message = "";
    };

    const submitForm = () => {
      console.log("success", values);

      resetForm();
    };

    return {
      formErrors,
      updateName,
      updateEmail,
      updateMessage,
      submitForm,
      validateField,
    };
  },
};
