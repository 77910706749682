<template>
  <a class="link" href="https://dribbble.com" target="_blank" rel="noreferrer">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 4.20001C16.3333 11.2 18.6667 16.45 19.25 23.1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.46667 22.1667C11.55 18.0833 14.4667 14.5833 24.3833 14.7"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.61667 12.5417C9.45 12.5417 15.0663 12.0983 21.483 6.70834"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "dribbble",
};
</script>

<style scoped lang="scss">
.link {
  --size: 28px;

  align-items: center;
  display: flex;
  height: var(--size);
  justify-content: center;
  width: var(--size);
}

svg {
  opacity: 0.3;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
    transition: 0.3s ease-in-out;
  }
}
</style>
