
import Review from "@/components/review.vue";

interface IReview {
  id: number;
  content: string;
  author: string;
  company: string;
}

const REVIEWS: IReview[] = [
  {
    id: 1,
    author: "Konrad Caba",
    content:
      "Jestem zadowolony ze współpracy. Bardzo fajny pomysł na logo. Jacek jest bardzo responsywny na wszelkie uwagi, komunikacja przebiegała szybko i sprawnie. Polecam!",
    company: "CABANOSS",
  },
  {
    id: 2,
    author: "Szymon Cywiński",
    content:
      "To have a vision of the project is a first step to create its own concept. The second step is to have someone who will exactly understand the vision of the creator. I ordered the complex project of the menu card for the new opening restaurant. Jacek has done the best project which I have seen ever. Jacek draws attention to each little detail, every project is very esthetic and comes on time. I can confirm a very strong recommendation for him. Just tell him what you want to create, he will make it real!",
    company: "Manager of Grodzka 9",
  },
  {
    id: 3,
    author: "Mariusz Ratyński",
    content:
      "Polecam! Świetne zrozumienie potrzeb klienta oraz doskonały kontakt 👍🏼",
    company: "CEO at Ratyński Design",
  },
  {
    id: 4,
    author: "Jasmine Carriere",
    content:
      "Jacek worked on a complex site for one of our clients. His work ethic, attention to details and overall developer knowledge was a huge asset to our team. Highly recommend Jacek for future ecomm projects.",
    company: "PM at Half Helix",
  },
];

export default {
  name: "reviews",
  components: { Review },

  setup() {
    const leftColumn = REVIEWS.filter((review) => review.id % 2 !== 0);

    const rightColumn = REVIEWS.filter((review) => review.id % 2 === 0);

    return { leftColumn, rightColumn, reviews: REVIEWS };
  },
};
