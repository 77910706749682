import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-040c2468"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header__navigation" }
const _hoisted_2 = { class: "header__navigation-list" }
const _hoisted_3 = { href: "/" }
const _hoisted_4 = { class: "header__social-media" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_linkedin = _resolveComponent("linkedin")!
  const _component_dribbble = _resolveComponent("dribbble")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", { 'header--active': _ctx.isActive }])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["header__navigation-item", {
            'header__navigation-item--active':
              _ctx.activeLink === item.name.toLowerCase(),
          }]),
            key: item.name
          }, [
            _createElementVNode("a", _hoisted_3, _toDisplayString(item.name), 1)
          ], 2))
        }), 128))
      ]),
      _createElementVNode("ul", _hoisted_4, [
        _createVNode(_component_linkedin),
        _createVNode(_component_dribbble)
      ])
    ])
  ], 2))
}