type Fields = "name" | "email" | "message";

interface IError {
  field: Fields;
  message: string;
}

export const validateContactForm = (
  name: string,
  email: string,
  message: string
): IError[] => {
  const errors: IError[] = [];

  if (!name || name.length === 0) {
    errors.push({ field: "name", message: "Name must be filled" });
  }

  if (Number(name)) {
    errors.push({ field: "name", message: "Name cannot be a number" });
  }

  if (!email || email.length === 0) {
    errors.push({ field: "email", message: "Email must be filled" });
  }

  if (!message || message.length === 0) {
    errors.push({ field: "message", message: "Message must be filled" });
  }

  return errors;
};
