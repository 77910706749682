import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e15b48d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review" }
const _hoisted_2 = { class: "review__content" }
const _hoisted_3 = { class: "review__author" }
const _hoisted_4 = { class: "review__author-metadata" }
const _hoisted_5 = { class: "review__author-name" }
const _hoisted_6 = { class: "review__company" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString($props.content), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString($props.author), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString($props.company), 1)
      ])
    ])
  ]))
}