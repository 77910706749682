import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a4b2e4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-textarea base-input" }
const _hoisted_2 = { class: "base-input__label" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
    _withDirectives(_createElementVNode("textarea", {
      class: "base-textarea__field",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      placeholder: $props.placeholder,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onChange', $setup.value)))
    }, null, 40, _hoisted_3), [
      [_vModelText, $setup.value]
    ])
  ]))
}