import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62cfc042"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clients" }
const _hoisted_2 = { class: "clients__list" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, ({ logo, alt }) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "clients__item",
          key: logo
        }, [
          _createElementVNode("img", {
            class: "clients__item-logo",
            src: require(`@/assets/clients/${logo}.svg`),
            alt: alt
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}