
import { defineComponent } from "vue";
import { PROJECTS } from "@/content/projects";
import Project from "@/components/project.vue";
import BaseButton from "@/components/base/base-button.vue";

export default defineComponent({
  name: "work",
  components: { BaseButton, Project },
  setup() {
    return { projects: PROJECTS };
  },
});
