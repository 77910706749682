
import { defineComponent } from "vue";

interface IValue {
  icon: string;
  name: string;
  description: string;
}

const VALUES: IValue[] = [
  {
    icon: "customer-first.svg",
    name: "Customer-first rule",
    description:
      "I treat each customer individualy but one thing that doesn’t change is I always reply in less than 1h to my clients messages.",
  },
  {
    icon: "clients-needs.svg",
    name: "Listening to client's needs",
    description:
      "According to customer-first rule, I’m focused on client’s goals during whole design and development process ",
  },
  {
    icon: "full-service.svg",
    name: "Full service man",
    description:
      "You don’t need to search for another freelancers for each of your needs. I’d be excited to design and build everything for you",
  },
  {
    icon: "quality.svg",
    name: "Quality over quantity",
    description:
      "I believe that quality is much more important than quantity. With it in mind I always take care of my work to ensure it is in highest possible quality.",
  },
];

export default defineComponent({
  name: "values",
  setup() {
    return { values: VALUES };
  },
});
