import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "base-input" }
const _hoisted_2 = { class: "base-input__label" }
const _hoisted_3 = ["type", "name", "placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "base-input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.fieldValue) = $event)),
      class: _normalizeClass(["base-input__field", { 'base-input__field--error': $props.error }]),
      type: $props.type,
      name: $props.name,
      placeholder: $props.placeholder,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onChange', $setup.fieldValue))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onBlur', $props.name)))
    }, null, 42, _hoisted_3), [
      [_vModelDynamic, $setup.fieldValue]
    ]),
    ($props.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ]))
}