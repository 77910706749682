
export default {
  name: "review",
  props: {
    content: {
      type: String,
    },
    author: {
      type: String,
    },
    company: {
      type: String,
    },
  },
};
