import { IProject } from "@/types";

export const PROJECTS: IProject[] = [
  {
    name: "Inspire Miodova",
    previewLink: "https://inspiremiodova.pl",
    fields: ["ux/ui", "frontend"],
    mockup: "",
  },
  {
    name: "Envard",
    mockup: "",
    fields: ["ux/ui", "frontend"],
    previewLink: "",
  },
];
