<template>
  <section id="contact" class="contact layout-wrapper">
    <h2 class="section-title">How can I help?</h2>

    <contact-form />
  </section>
</template>

<script>
import ContactForm from "@/components/contact-form";
export default {
  name: "contact",
  components: { ContactForm },
};
</script>

<style scoped lang="scss">
.contact {
  background-color: #001d03;
  min-height: 30vh;
  width: 100%;
}
</style>
