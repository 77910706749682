import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34170ec1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.externalLink)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          target: "_blank",
          rel: "noreferrer",
          class: _normalizeClass(["base-button", {
      'base-button--secondary': _ctx.variant === 'secondary',
      'base-button--primary': _ctx.variant === 'primary',
      'base-button--full-width': _ctx.fullWidth,
    }]),
          href: _ctx.externalLink
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.scrollTo)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: _normalizeClass(["base-button", {
      'base-button--secondary': _ctx.variant === 'secondary',
      'base-button--primary': _ctx.variant === 'primary',
      'base-button--full-width': _ctx.fullWidth,
    }]),
          href: _ctx.scrollTo
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.href)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          class: _normalizeClass(["base-button", {
      'base-button--secondary': _ctx.variant === 'secondary',
      'base-button--primary': _ctx.variant === 'primary',
      'base-button--full-width': _ctx.fullWidth,
    }]),
          to: _ctx.href
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["class", "to"]))
      : _createCommentVNode("", true),
    (_ctx.type !== '')
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: _normalizeClass(["base-button", {
      'base-button--secondary': _ctx.variant === 'secondary',
      'base-button--primary': _ctx.variant === 'primary',
      'base-button--full-width': _ctx.fullWidth,
    }]),
          type: _ctx.type,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 10, _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}