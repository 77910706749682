
import { ref } from "vue";

export default {
  name: "base-input",
  emits: ["onChange"],
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    error: {
      type: String,
      default: null,
    },
  },
  setup() {
    const fieldValue = ref("");

    return { fieldValue };
  },
};
